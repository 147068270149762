import classNames from 'classnames';
import React from 'react';

interface FullwidthIllustrationProps {
  illustration: JSX.Element;
  classes?: string;
  containerClasses?: string;
  flipHorizontally?: boolean;
}

/**
 * Special wrapper for full width SVG illustrations because
 * of Safari's special aspect ratio calculations that make the contents
 * of the SVG a different dimension than the parent wrapping <svg> element.
 *
 * The SVG is artifically wider than the wrapper thus eliminating any whitespace.
 * The wrapper then cuts off the overflowing edges leaving
 * a true fullwidth illustration without whitespace on the sides.
 */

const FullwidthIllustration = ({
  illustration,
  classes,
  containerClasses,
  flipHorizontally = false
}: FullwidthIllustrationProps) => {
  return (
    <div className={classNames('w-full', 'overflow-hidden', flipHorizontally && 'scale-x-[-1] scale-y-[1]', classes)}>
      <div className={classNames('pt-[10vw]', 'w-[104vw]', '-left-[2vw]', 'mx-auto', 'relative', containerClasses)}>
        <div className={classNames('w-full', 'h-full', 'absolute', 'left-0', 'right-0', 'top-0', 'bottom-0')}>
          {illustration}
        </div>
      </div>
    </div>
  );
};

export default FullwidthIllustration;
