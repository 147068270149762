import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import PlainTextLayout from './PlainTextLayout';

const PrivacyPolicySection = () => {
  const { t } = useTranslation();

  return (
    <PlainTextLayout
      title={t('Privacy Policy')}
      id="privacy-policy"
      content={[
        <>
          <b>
            <Trans>
              Thank you for visiting Paima Studios (“Paima”). This Privacy Policy describes the personal data handling
              policies of Paima as a data controller, including the following:
            </Trans>
          </b>
        </>,
        <>
          <ol className="list-decimal pl-36">
            <li>
              <Trans>
                What personal data we collect, including from Paima online and mobile applications, services and our
                website at https://paimastudios.com (collectively, the “Services”);
              </Trans>
            </li>
            <li>
              <Trans>How we use the data;</Trans>
            </li>
            <li>
              <Trans>With whom we may share the personal data;</Trans>
            </li>
            <li>
              <Trans>Legal basis for processing the data;</Trans>
            </li>
            <li>
              <Trans>Storage and security of your data;</Trans>
            </li>
            <li>
              <Trans>Your Privacy Rights, Choices and Disclosures, including those for Europe and California;</Trans>
            </li>
            <li>
              <Trans>Users outside the United States;</Trans>
            </li>
            <li>
              <Trans>Links to other sites;</Trans>
            </li>
            <li>
              <Trans>Privacy Policy updates; and</Trans>
            </li>
            <li>
              <Trans>Contact Information.</Trans>
            </li>
          </ol>
        </>,
        <>
          <h2 className="text-20 mb-10 mt-18 font-bold">
            <Trans>1. Personal Data We Collect or Process</Trans>
          </h2>
          <ol className="list-[lower-latin] pl-26">
            <li>
              <Trans>
                The Services capture some information about you automatically utilizing background local storage and
                session storage technologies (“Cookies”). Cookies are small files or other pieces of data which are
                downloaded or stored on your computer or other device, that can be tied to information about your use of
                the Services. Examples of information of this type are your anonymized IP address, the browser you are
                using, the operating system you are using, the pages on the website that you visit and navigation
                activity. You may change your browser setting to decline the use of Cookies.
              </Trans>
              <br />
              <Trans
                i18nKey="We use Google Analytics and Google Tag Manager, which use Cookies to identify the frequency of use of certain areas of our website and to identify preferences. For more information regarding how Google collects, uses, and shares your information, please visit https://www.google.com/policies/privacy/partners/. The information regarding your use of this website that is generated by the cookie (including your truncated IP address) is transferred to a Google server in the United States and stored there. Google will use this information to analyze your use of the Services, compile reports on activity for us, and perform further services associated with website use and Internet use. Google may also transmit this information to third parties where required by law or to the extent that third parties process these data on behalf of Google. You can deactivate Google Analytics using a browser add-on if you do not wish the analysis to take place. You can download the add-on here: https://tools.google.com/dlpage/gaoptout?hl=en. We operate social media pages on third party networks and have social media icons on our website. When you visit or link to our social media pages, data is processed both by us and the applicable social media provider. Social media providers are unaffiliated with us, and we are not responsible for the content or privacy practices of social media providers. Social media providers have their own terms of use and privacy policies, and we encourage you to review those policies whenever you visit their websites or interact with their platforms."
                components={[
                  <a className="underline" href="https://www.google.com/policies/privacy/partners/" />,
                  <a className="underline" href="https://tools.google.com/dlpage/gaoptout?hl=en" />
                ]}
              />
            </li>
            <br />
            <li>
              <Trans>
                We also collect information that you provide to us voluntarily. Paima collects: (i) a crypto wallet
                address if you buy NFTs (you pay with a blockchain wallet); (ii) an email address if you choose to
                subscribe to receive updates or news from us; (iii) your name, email and message if you use our Contact
                form to communicate with us; and (iv) any additional information that you provide to us in the contents
                of your message when you contact us via email or through our Contact form. We do not require you to
                register an account or provide an email address. The Services do not store, send, or receive crypto
                assets, as crypto assets exist only by virtue of the ownership record maintained on its supporting
                blockchain. Any transfer of crypto assets occurs within the supporting blockchain and not on the
                Services.
              </Trans>
            </li>
            <br />
            <li>
              <Trans>
                We do not knowingly collect information from users of our website under the age of 18 and/or their
                internet usage, and such persons are not authorized to use the Services. If we obtain actual knowledge
                that a user is under 18, we will take steps to remove that user's personal information from our
                databases. By using the Services, you represent that you are at least 18 years old. You also represent,
                by accessing or using the Services, that you are of legal age to enter into legal agreements.
              </Trans>
            </li>
          </ol>
        </>,
        <>
          <h2 className="text-20 mb-8 mt-18 font-bold">
            <Trans>2. How We Use the Data</Trans>
          </h2>
          <Trans>
            We use the personal data we collect to respond to your inquiries, complete transactions you initiate,
            provide or enhance the Services you request, provide updates and other important information related to the
            Services (if you opt in), administer the Services, and for other legitimate business purposes.
          </Trans>
        </>,
        <>
          <h2 className="text-20 mb-8 mt-18 font-bold">
            <Trans>3. With Whom We Share Personal Data</Trans>
          </h2>
          <Trans>
            Paima may share your information with its affiliates (within or outside your country of residence) in order
            to perform our Services.
          </Trans>{' '}
          <br />
          <br />
          <Trans>
            We may also share your personal data to third parties (within or outside your country of residence) who
            perform services on our behalf, including without limitation our technology providers and professional
            advisors.
          </Trans>{' '}
          <br />
          <br />
          <Trans>
            Purchases of NFTs are completed via third party non-custodial wallets which are connected to the blockchain.
            Public blockchains provide transparency into transactions and Paima is not responsible for preventing or
            managing information broadcasted on a blockchain.
          </Trans>
          <br />
          <br />
          <Trans>
            Paima may disclose any information we have about you if we determine that such disclosure is necessary in
            connection with any investigation or complaint regarding your use of the Services, or to identify, contact
            or bring legal action against someone who may be causing injury to or interference with (either
            intentionally or unintentionally) Paima’s rights or property, or the rights or property of visitors to or
            users of the Services. Paima reserves the right at all times to disclose any information that Paima deems
            necessary to comply with any applicable law, regulation, legal process or governmental request. Paima also
            may disclose your information when Paima determines that applicable law requires or permits such disclosure,
            including exchanging information with other companies and organizations for fraud protection purposes.
          </Trans>{' '}
          <br />
          <br />
          <Trans>
            We may share your data with a third party in the event of any reorganization, merger, sale, joint venture,
            assignment, transfer, liquidation or other disposition of all or any portion of our business, assets or
            stock with such third party.
          </Trans>{' '}
          <br />
          <br />
          <Trans>
            We may share aggregated and de-identified information with third parties (within or outside your country of
            residence) for analytical, research or other similar purposes.
          </Trans>{' '}
          <br />
          <br />
          <Trans>
            Paima may obtain your written consent from time to time in electronic form by using online agreements or
            other acknowledgements on the Services, including for any other contemplated uses of your personal data not
            addressed in this Privacy Policy. Please read all online agreements carefully before accepting them.
          </Trans>
        </>,
        <>
          <h2 className="text-20 mb-8 mt-18 font-bold">
            <Trans>4. Grounds for Using Your Personal Data</Trans>
          </h2>
          <Trans>We rely on the following legal grounds to process your personal information:</Trans>
          <br /> <br />
          <p className="mb-14">
            <span className="underline mr-6">
              <Trans>Consent.</Trans>
            </span>
            <Trans
              i18nKey="Some uses of your personal data as described in this Privacy Policy are subject to your consent, such as email communications. To withdraw your consent, please contact us at contact@paimastudios.com. You may refrain from providing, or withdraw, your consent for Cookies via your browser and mobile settings."
              components={[
                <a
                  href="mailto:contact@paimastudios.com"
                  className="underline cursor-pointer hover:text-volcaneers-primary-dark"
                />
              ]}
            />
          </p>
          <p className="mb-14">
            <span className="underline mr-6">
              <Trans>Performance of a contract.</Trans>
            </span>
            <Trans>
              We may need to collect and use your personal information to enter into a contract with you and to perform
              Services that you request.
            </Trans>
          </p>
          <p className="mb-14">
            <span className="underline mr-6">
              <Trans>Legitimate Interests.</Trans>
            </span>
            <Trans>
              We may use your personal information for our legitimate interests to provide our Services and to improve
              our Services and the content contained thereon. We may use technical information as described in this
              Privacy Policy and use personal information for our marketing purposes consistent with our legitimate
              interests and any choices that we offer or consents that may be required under applicable law.
            </Trans>
          </p>
          <p className="mb-14">
            <span className="underline mr-6">
              <Trans>Compliance with Legal Obligations.</Trans>
            </span>
            <Trans>We may use your personal information as necessary to comply with our legal obligations.</Trans>
          </p>
        </>,
        <>
          <h2 className="text-20 mb-8 mt-18 font-bold">
            <Trans>5. Storage and Protection of Your Personal Data</Trans>
          </h2>
          <Trans>
            We retain the personal data we collect for so long as reasonably necessary to fulfill the purposes for which
            the data was collected and to perform our contractual and legal obligations. Notwithstanding the generality
            of the foregoing, we store email addresses until the user requests to be unsubscribed. In addition,
            transactional data broadcasted to the blockchain is immutable and may not be removed or changed.
          </Trans>{' '}
          <br />
          <br />
          <Trans>
            We take reasonable administrative, physical and technical precautions to protect your personal data and
            communications between us. This includes, when required or as we deem appropriate and feasible under the
            circumstances, encryption and written commitments from third parties that may have access to your data that
            they will protect the data with reasonable safeguards.
          </Trans>{' '}
          <br />
          <br />
          <Trans>
            No Internet or e-mail transmission is ever fully secure or error free, however. We therefore cannot
            guarantee absolute security of your data, and we are not responsible for processes and networks that we do
            not control. Users assume the risk of security breaches and the consequences resulting from them. Please be
            careful in deciding what information you send to us via email or over the Internet.
          </Trans>
        </>,
        <>
          <h2 className="text-20 mb-8 mt-18 font-bold">
            <Trans>6. Your Rights, Choices and Disclosures</Trans>
          </h2>
          <ol className="list-[lower-latin] pl-26">
            <li>
              <b>
                <Trans>General.</Trans>
              </b>{' '}
              <Trans
                i18nKey="This section is subject to specific disclosures on privacy rights set forth below. We are committed to facilitate the exercise of your rights granted by the laws of your jurisdiction, which may include the right to request the correction, modification or deletion of your personal information and the right to opt out of the sale of your personal information (as applicable). We will do our best to honor your requests subject to any legal and contractual obligations. If you would like to make a request, cancel your account or request we delete or no longer use your account information to provide you Services, contact us at contact@paimastudios.com."
                components={[
                  <a
                    href="mailto:contact@paimastudios.com"
                    className="underline cursor-pointer hover:text-volcaneers-primary-dark"
                  />
                ]}
              />
              <br /> <br />
              <Trans>
                Transactional data broadcasted to the blockchain is immutable and may not be removed or changed.
              </Trans>
              <br /> <br />
              <Trans>
                Subject to local law, you may have additional rights under the laws of your jurisdiction regarding your
                personal data, such as the right to complain to your local data protection authority.
              </Trans>
            </li>
            <br />
            <li>
              <b>
                <Trans>Your European Privacy Rights and Choices.</Trans>
              </b>{' '}
              <Trans>
                If you are located in the European Union, the United Kingdom or another jurisdiction that has adopted
                laws substantially similar to GDPR, your privacy rights include the following:
              </Trans>
              <ul className="list-disc pl-28 mt-20">
                <li>
                  <Trans>
                    Transparency and the right to information. Through this policy we explain how we use and share your
                    information. However, if you have questions or need additional information you can contact us any
                    time.
                  </Trans>
                </li>
                <br />
                <li>
                  <span className="underline">
                    <Trans>Right of access, correction, restriction of processing, erasure.</Trans>
                  </span>
                  <Trans
                    i18nKey="You may contact us to request information about the personal data we have collected from you and to request the correction, modification or deletion of such personal information, which requests we will do our best to honor subject to any legal and contractual obligations. To make a request or to ask us a question about our data practices, please contact us via email at contact@paimastudios.com."
                    components={[
                      <a
                        href="mailto:contact@paimastudios.com"
                        className="underline cursor-pointer hover:text-volcaneers-primary-dark"
                      />
                    ]}
                  />
                </li>
                <br />
                <li>
                  <span className="underline">
                    <Trans>Right to withdraw your consent at any time.</Trans>
                  </span>
                  <Trans>
                    When we process your personal data based on your consent, you have the right to withdraw it at any
                    time.
                  </Trans>
                </li>
                <br />
                <li>
                  <span className="underline">
                    <Trans>Right to object at any time.</Trans>
                  </span>
                  <Trans>
                    You have the right to object at any time to receiving marketing or promotional materials from us by
                    either following the opt-out instructions in commercial e-mails or by contacting us, as well as the
                    right to object to any processing of your personal data based on your specific situation. In the
                    latter case, we will assess your request and provide a reply in a timely manner, according to our
                    legal and contractual obligations. Some non-marketing communications are not subject to a general
                    opt-out, such as communications about transactions and disclosures to comply with legal
                    requirements.
                  </Trans>
                </li>
                <br />
                <li>
                  <span className="underline">
                    <Trans>Right to data portability.</Trans>
                  </span>
                  <Trans>You have the right to data portability of your own personal data by contacting us.</Trans>
                </li>
                <br />
                <li>
                  <span className="underline">
                    <Trans>Right not to be subject to an automated decision, including profiling.</Trans>
                  </span>
                  <Trans>
                    We do not make automated decisions using your personal data that may negatively impact you.
                  </Trans>
                </li>
                <br />
                <li>
                  <span className="underline">
                    <Trans>Right to lodge a complaint with a supervisory authority.</Trans>
                  </span>
                  <Trans>
                    If you consider that the processing of your personal data infringes your privacy rights according to
                    the European Privacy regulation, you have the right to lodge a complaint with a supervisory
                    authority, in the member state of your habitual residence, place of work, or place of the alleged
                    infringement.
                  </Trans>
                </li>
              </ul>
            </li>
            <br />
            <li>
              <b>
                <Trans>California Disclosures</Trans>
              </b>
              <br /> <br />
              <span className="underline">
                <Trans>California “Shine the Light” Information-Sharing Disclosure:</Trans>
              </span>{' '}
              <Trans>
                Paima does not disclose any information about you to third parties for their direct marketing purposes.
              </Trans>
              <br /> <br />
              <span className="underline">
                <Trans>California Do Not Track Disclosure:</Trans>
              </span>{' '}
              <Trans>We do not respond to Do Not Track browser settings.</Trans>
              <br /> <br />
              <span className="underline">
                <Trans>California Consumer Privacy Act Disclosures:</Trans>
              </span>{' '}
              <Trans>Paima is not a business subject to the California Consumer Privacy Act.</Trans>
              <br /> <br />
            </li>
          </ol>
        </>,
        <>
          <h2 className="text-20 mb-8 mt-18 font-bold">
            <Trans>7. Users Outside the United States</Trans>
          </h2>
          <Trans>
            The servers that support the Services are located in the United States. If you are an individual from the
            European Union, Canada or any other jurisdiction with laws or regulations governing personal data
            collection, use, and disclosure that differ from United States laws, please be advised that we may store the
            information we collect in the United States which is not deemed an “adequate jurisdiction” by the European
            regulatory authorities. Personal data may also be transferred from the country of your residence to other
            countries, including the United States. By visiting or using our Services, you are consenting to the
            transfer of your information from your jurisdiction to the United States.
          </Trans>
        </>,
        <>
          <h2 className="text-20 mb-8 mt-18 font-bold">
            <Trans>8. Links to Other Sites</Trans>
          </h2>
          <Trans>
            Our Services may contain links to other websites for your convenience or information. These websites may be
            operated by companies unaffiliated with Paima, and we are not responsible for the content or privacy
            practices of those websites. Linked websites may have their own terms of use and privacy policies, and we
            encourage you to review those policies whenever you visit the websites.
          </Trans>
        </>,
        <>
          <h2 className="text-20 mb-8 mt-18 font-bold">
            <Trans>9. Privacy Policy Changes</Trans>
          </h2>
          <Trans>
            We may update this Privacy Policy from time to time and without prior notice to you to reflect changes in
            our information practices, and any such amendments shall apply to information already collected and to be
            collected. Your continued use of the Services after any changes to our Privacy Policy indicates your
            agreement with the terms of the revised Privacy Policy. Please review this Privacy Policy periodically and
            especially before you provide personal data to us. If we make material changes to this Privacy Policy, we
            will notify you here, by email or by means of a notice on our home page. The date of the last update of the
            Privacy Policy is indicated at the top of this Privacy Policy.
          </Trans>
        </>,
        <>
          <h2 className="text-20 mb-8 mt-18 font-bold">
            <Trans>10. Questions and Contact Information</Trans>
          </h2>
          <Trans
            i18nKey="Please contact us if you have any questions about our Privacy Policy. You may contact us by emailing us at contact@paimastudios.com."
            components={[
              <a
                href="mailto:contact@paimastudios.com"
                className="underline cursor-pointer hover:text-volcaneers-primary-dark"
              />
            ]}
          />
        </>
      ]}
      bottomNote={t('Paima Team')}
    />
  );
};

export default PrivacyPolicySection;
