import classNames from 'classnames';
import * as React from 'react';

import OvalIllustration from '../../../static/images/illustration-oval.inline.svg';

import FullwidthIllustration from './volcaneers/FullwidthIllustration';

interface PlainTextLayoutProps {
  id?: string;
  title?: string;
  subtitle?: string;
  content: React.ReactElement | React.ReactElement[] | React.ReactFragment;
  bottomNote?: string;
  wrapperClassName?: string;
}

const PlainTextLayout = ({ id, title, subtitle, content, bottomNote, wrapperClassName }: PlainTextLayoutProps) => {
  return (
    <section id={id} className={classNames(wrapperClassName)}>
      <div className="container-narrow pb-[10vw] md:pb-[12vw] lg:pb-[0] relative z-30">
        <div className="pt-32 md:pt-64">
          {subtitle && <h2 className="font-heading font-bold text-18 leading-5 mb-16 text-center">{subtitle}</h2>}
          {title && (
            <h1 className="font-heading font-bold text-32 leading-10 md:text-48 md:leading-[57px] mb-28 md:mb-40 text-center">
              {title}
            </h1>
          )}
          <div className="space-y-24 md:space-y-28 text-16 leading-6 md:text-18 md:leading-7">
            {Array.isArray(content) ? (
              content.map((item, index) => <div key={index}>{item}</div>)
            ) : (
              <div>{content}</div>
            )}
          </div>
          {bottomNote && (
            <div className="w-full flex flex-col items-end mt-56">
              <p className="text-18 leading-7 font-semibold italic">Paima Team</p>
              <img
                className="w-[164px] h-[40px] mt-4"
                src="/images/paima-logo-horizontal-color.svg"
                alt="Paima Studios Logo"
              />
            </div>
          )}
        </div>
      </div>
      <FullwidthIllustration illustration={<OvalIllustration />} containerClasses="pt-[10vw] fill-volcaneers-black" />
    </section>
  );
};

export default PlainTextLayout;
