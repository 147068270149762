import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import CookieConsentBanner from '../components/CookieConsentBanner';
import Footer from '../components/v2/Footer';
import Header from '../components/v2/header/Header';
import Layout from '../components/v2/Layout';
import PrivacyPolicySection from '../components/v2/PrivacyPolicySection';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Privacy Policy')}>
      <Header showComingSoonCountdown={false} redirectToBuyNft />
      <CookieConsentBanner />
      <PrivacyPolicySection />
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
